import React, { useState } from 'react'
import {Box,Button,Card,Grid,TextField,Typography, Container, Autocomplete, Snackbar, Alert, Grow} from "@mui/material";
import Heading from '../Components/Heading';
import Breadcrumbs from '../Components/Breadcrumbs';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import FactoryProjectAssignTab from '../Components/FactoryProjectComps/FactoryProjectAssignTab';
import AssignedFactoryProjectsTab from '../Components/FactoryProjectComps/AssignedFactoryProjectsTab';
import IconBreadcrumbs from '../Components/Breadcrumbs';
import StoreFactoryProject from '../Components/FactoryProjectComps/StoreFactoryProject';
import PurchaseTable from '../Components/FactoryProjectComps/PurchaseTable';
import MaterialInwards from '../Components/FactoryProjectComps/MaterialInwards';
import MaterialIssued from '../Components/FactoryProjectComps/MaterialIssued';
import MaterialIssueProjectList from '../Components/FactoryProjectComps/MaterialIssueProjectList';




export default function FactoryProjectsPage() {

 
  const [value, setValue] = useState("1");
  const [isAddedCartSnackbarOpen, setIsAddedCartSnackbarOpen] = useState(false);
  const [Notification, setNotification] = useState("");

  // Tab functionality
  const handleChange = (event, newValue) => {
      setValue(newValue);
  };

  const handleCloseAddedCartSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setIsAddedCartSnackbarOpen(false);
  };

  function GrowTransition(props) {
    return <Grow {...props} direction="up" />;
  }


  const role = JSON.parse(localStorage.getItem('role'));

  const roles = ['Project Manager', 'Store', 'Purchase',];

  const access = ['Super Admin', 'Factory Admin', 'Human Resources', 'Project Manager',]

  return (
  <Box>

      <Snackbar
        open={isAddedCartSnackbarOpen}
        autoHideDuration={1500}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        TransitionComponent={GrowTransition}
        onClose={handleCloseAddedCartSnackbar}>
        <Alert onClose={handleCloseAddedCartSnackbar} severity='success' variant="filled" sx={{ width: '100%' }}>
          {Notification}
        </Alert>
      </Snackbar>

    <Box py={2} px={1}>
    <IconBreadcrumbs 
    previous={'Home'}
    current={'Factory Project'}
    link1={`/home`}
    link2={'/home/project'}
    currentSection={ value !== "1" ? 'Factory Projects Creation' : 'Assigned Factory Projects' }
    
    />
    </Box>

    <Container>
        {
    role === 'Super Admin' ?
      <Box py={3}>
      <Heading  title={ value !== "1" ? 'Factory Projects Creation' : 'Assigned Factory Projects' }/>
      </Box> 
      :
      <Box py={3}>
      <Heading  title={'Your Factory Projects'}/>
      </Box> 
     }
    </Container>

    
      <Container>
        <Box sx={{ p: 3 }}>
          <TabContext value={value}>
            <TabList variant="scrollable" scrollButtons="auto" onChange={handleChange} aria-label="tabs">
              <Tab sx={{ fontSize: 17, fontWeight: 700, color: '#040404', marginRight: 5 }} label="Assigned Projects" value="1" />
              {access.includes(role) ? (
                <Tab sx={{ fontSize: 17, fontWeight: 700, color: '#040404', marginRight: 5 }} label="Project Creation" value="2" />
              ) : null}
              <Tab sx={{ fontSize: 17, fontWeight: 700, color: '#040404', marginRight: 5 }} label="Material Inwards" value="3" />
              <Tab sx={{ fontSize: 17, fontWeight: 700, color: '#040404', marginRight: 5 }} label="Material Issued" value="4" />
              {roles.includes(role) && (
                [
                  role === 'Store' && (
                    <Tab key="store" sx={{ fontSize: 17, fontWeight: 700, color: '#040404', marginRight: 5 }} label="Store" value="2" />
                  ),
                  role === 'Purchase' && (
                    <Tab key="purchase" sx={{ fontSize: 17, fontWeight: 700, color: '#040404', marginRight: 5 }} label="Purchase" value="3" />
                  )
                ]
              )}
            </TabList>
            <TabPanel value="1">
              <Box>
                <AssignedFactoryProjectsTab />
              </Box>
            </TabPanel>
            {access.includes(role) ? (
              <TabPanel value="2">
                <Box>
                  <FactoryProjectAssignTab setValue={setValue} isAddedCartSnackbarOpen={isAddedCartSnackbarOpen} setIsAddedCartSnackbarOpen={setIsAddedCartSnackbarOpen} setNotification={setNotification} />
                </Box>
              </TabPanel>
            ) : null}

            <TabPanel value="3">
              <Box>
                <MaterialInwards />
              </Box>
            </TabPanel>

            <TabPanel value="4">
              <Box>
                <MaterialIssueProjectList />
              </Box>
            </TabPanel>

            {role === 'Store' && (
              <TabPanel value="2">
                <Box>
                  <StoreFactoryProject setValue={setValue} />
                </Box>
              </TabPanel>
            )}
            {role === 'Purchase' && (
              <TabPanel value="3">
                <Box>
                  <PurchaseTable setValue={setValue} />
                </Box>
              </TabPanel>
            )}
          </TabContext>
        </Box>
      </Container>

  </Box>
  )
}
