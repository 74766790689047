import React, { useState, useEffect } from "react";
import { Typography, TextField, Card, Button, TableFooter, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Grid, Box, Container, TablePagination, IconButton, Autocomplete } from "@mui/material";
import { expenseDocs, fetch_expense_category, getProjectCode, get_all_maintenance_expense, get_all_rental_expense, get_expense_summary, get_summary_Report, methodGet, methodPost } from "../../API_Service/API_Links";
import axios from "axios";
import { FilterData, FilterFourData } from "../FilterData/FilterData";
import Filter from "../FilterData/Filter";
import { PlayCircleFilled, Image } from '@mui/icons-material';
import SnackBar from "../SnackBar";
import { SummaryExport } from "./SummaryExport";
import { SummaryAllExport } from "./SummaryAllExport";

export default function NewSummaryExpense() {

    const [open, setOpen] = useState(false);
    const [status, setStatus] = useState(false);
    const [color, setColor] = useState(false);
    const [message, setMessage] = useState("");
    const [data, setData] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [search, setSearch] = useState("");
    const [allSummary, setAllSummary] = useState([]);
    const [ExportSummary, setExportSummary] = useState([]);
    const [EmployeeName, setEmployeeName] = useState("");
    const [EmployeeId, setEmployeeId] = useState("");
    const [fromDate, setFromDate] = useState('');
    const [toDate, setToDate] = useState('');
    const [SelectedProjectCode, setSelectedProjectCode] = useState('');
    const [SelectedCategory, setSelectedCategory] = useState('');
    const [spendDate , setSpendDate] = useState('');

    const [employeeIdSearch, setEmployeeIdSearch] = useState('');
    const [employeeNameSearch, setEmployeeNameSearch] = useState('');
    const [projectCodeSearch, setProjectCodeSearch] = useState('');
    const [categorySearch, setcategorySearch] = useState('');
    const [dateSearch, setdateSearch] = useState('');

    const [projectCodeList, setprojectCodeList] = useState([]);
    const [categoryList, setcategoryList] = useState([]);
    const [isFilterOn, setIsFilterOn] = useState(false);

    const getAllSummary = () => {
        const sendData = new FormData()
        sendData.append('EmployeeId', '')
        sendData.append('FromDate', '')
        sendData.append('ToDate', '')
        axios({
            method: methodPost,
            url: get_expense_summary,
            data: sendData
        })
            .then((res) => {
                if (!res.data.error) {
                    setMessage(res.data.message);
                    setStatus(true);
                    setColor(true);
                    setOpen(true);
                    setAllSummary(res.data.data);
                } else {
                    setMessage(res.data.message);
                    setStatus(false);
                    setColor(false);
                }
            })
            .catch((err) => {
                alert("Oops something went wrong " + err);
            });
    }

    useEffect(() => {
        getAllSummary();
    }, [EmployeeId , fromDate , toDate , SelectedCategory , SelectedProjectCode]);

    useEffect(() => {
        axios({
            method: methodGet,
            url: fetch_expense_category,
        })
            .then((res) => {
                if (res.data.error) {
                    setMessage(res.data.message);
                    setStatus(false);
                    setColor(false);
                } else {
                    setMessage(res.data.message);
                    setStatus(true);
                    setColor(true);
                    setcategoryList(res.data.data)
                }
            })
            .catch((err) => {
                alert("Oops something went wrong " + err);
            });
    }, [])

    useEffect(() => {
        axios({
            method: methodGet,
            url: getProjectCode,
        })
            .then((res) => {
                if (res.data.error) {
                    setMessage(res.data.message);
                    setStatus(false);
                    setColor(false);
                } else {
                    setMessage(res.data.message);
                    setStatus(true);
                    setColor(true);
                    setprojectCodeList(res.data.data)
                }
            })
            .catch((err) => {
                alert("Oops something went wrong " + err);
            });
    }, [])


    const getFilteredSummary = () => {
            const sendData = new FormData()
            sendData.append('EmployeeId', EmployeeId)
            sendData.append('FromDate', fromDate)
            sendData.append('ToDate', toDate)
            sendData.append('ProjectCode', SelectedProjectCode)
            sendData.append('Category', SelectedCategory)
            axios({
                method: methodPost,
                url: get_expense_summary,
                data: sendData
            })
                .then((res) => {
                    if (!res.data.error) {
                        setMessage(res.data.message);
                        setStatus(true);
                        setColor(true);
                        setOpen(true);
                        setAllSummary(res.data.data);
                        setIsFilterOn(true);
                    } else {
                        setMessage(res.data.message);
                        setStatus(false);
                        setColor(false);
                        setOpen(true);
                        setIsFilterOn(false);
                    }
                })
                .catch((err) => {
                    alert("Oops something went wrong " + err);
                });
        }


    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const keys = Object.keys(allSummary[0] || {});

    const removeFilter = () =>{
        setEmployeeName("")
        setEmployeeId("")
        setFromDate("")
        setToDate("")
        setSelectedProjectCode("")
        setSelectedCategory("")
        setIsFilterOn(false);
        getAllSummary();
    }

    const options = projectCodeList && projectCodeList.map((i, index)=>{
        const obj = {
            "label": i.projectCode,
              "id":index      
    }
    return obj;
    })

    const options1 = categoryList && categoryList.map((i, index) => {
        const obj = {
            "label": i.ExpenseCategoryName,
            "id": i.ExpenseCategoryId
        }
        return obj;
    })


    return (
        <Box boxShadow={4} bgcolor='#EDF4F4' py={2}>
            <SnackBar
                open={open}
                message={message}
                setOpen={setOpen}
                status={status}
                color={color}
            />
            <Container>
                <Box component={Card} p={4}>
                    <Grid container spacing={2} justifyContent='start'>
                        <Grid item xs={3} sm={3} md={3} lg={3}>
                            <Box mt={1}>
                                <TextField
                                    fullWidth
                                    id="EmployeeName"
                                    label="Employee ID"
                                    variant="outlined"
                                    size='small'
                                    color='secondary'
                                    value={EmployeeId}
                                    onChange={(e) => setEmployeeId(e.target.value)}
                                />
                            </Box>
                        </Grid>
                        <Grid item xs={3} sm={3} md={3} lg={3}>
                            <Box mt={1}>
                                <TextField
                                    fullWidth
                                    id="EmployeeName"
                                    label="Start Date"
                                    type='date'
                                    variant="outlined"
                                    size='small'
                                    color='secondary'
                                    value={fromDate}
                                    InputLabelProps={{ shrink: true }}
                                    onChange={(e) => setFromDate(e.target.value)}
                                />
                            </Box>
                        </Grid>

                        <Grid item xs={3} sm={3} md={3} lg={3}>
                            <Box mt={1}>
                                <TextField
                                    id="EmployeeName"
                                    label="To Date"
                                    type='date'
                                    fullWidth
                                    variant="outlined"
                                    size='small'
                                    color='secondary'
                                    value={toDate}
                                    InputLabelProps={{ shrink: true }}
                                    onChange={(e) => setToDate(e.target.value)}
                                />
                            </Box>
                        </Grid>

                        <Grid item xs={3} sm={3} md={3} lg={3}>
                            <Box mt={1}>
                                <Autocomplete
                                    disablePortal
                                    id="combo-box-demo"
                                    options={options}
                                    value={SelectedProjectCode}
                                    onChange={(event, value) => setSelectedProjectCode(value.label)}
                                    renderInput={(params) => (<TextField {...params} label="Project Code"
                                        sx={{ width: "100%" }} variant="outlined"
                                        size="small" color="secondary"
                                    />
                                    )}
                                />
                            </Box>
                        </Grid>
                        <Grid item xs={3} sm={3} md={3} lg={3}>
                            <Box mt={1}>
                                <Autocomplete
                                    disablePortal
                                    id="combo-box-demo"
                                    options={options1}
                                    value={SelectedCategory}
                                    onChange={(event, value) => setSelectedCategory(value.label)}
                                    renderInput={(params) => (<TextField {...params} label="Category"
                                        sx={{ width: "100%" }} variant="outlined"
                                        size="small" color="secondary"
                                    />
                                    )}
                                />
                            </Box>
                        </Grid>
                            {
                           isFilterOn ? 
                           <Grid item xs={3} sm={3} md={2} lg={2}>
                            <Box display='flex' justifyContent='center'>
                            <Button onClick={removeFilter} variant='contained' sx={{ bgcolor: 'secondary.main', my: 1 }} >Remove Filter</Button>
                            </Box>
                            </Grid>
                            :
                            <Grid item xs={3} sm={3} md={2} lg={2}>
                            <Box display='flex' justifyContent='center'>
                            <Button onClick={getFilteredSummary} variant='contained' sx={{ bgcolor: 'secondary.main', my: 1 }} >FILTER SUMMARY</Button>
                            </Box>
                            </Grid>
                            }


                        <Grid item xs={3} sm={3} md={2} lg={2}>
                            <Box display='flex' justifyContent='center'>
                                <SummaryAllExport
                                    fileName='Summary'
                                    setMessage={setMessage}
                                    setStatus={setStatus}
                                    setOpen={setOpen}
                                    setColor={setColor}
                                    EmployeeId={EmployeeId}
                                    fromDate={fromDate}
                                    toDate={toDate}
                                    SelectedCategory={SelectedCategory}
                                    SelectedProjectCode={SelectedProjectCode}
                                    setIsFilterOn={setIsFilterOn}
                                />
                            </Box>
                        </Grid>
                    </Grid>


                </Box>
            </Container>

            <Container>
                <Box mt={2} py={4}>
                    <Grid container spacing={1}>
                    <TableContainer sx={{ border: '1px solid silver' }} >
                    <Table>
                    <TableHead sx={{ whiteSpace: 'nowrap', bgcolor: 'success.main' }}>
                    <TableRow>
                    {keys.map((key) => (
                    <TableCell align="center" sx={{ color: 'white', fontWeight: 500, textAlign: 'center' }} key={key}>
                    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                    <Box sx={{ my: 'auto', textTransform: 'uppercase' }}>
                    {key}
                    </Box>
                    {key === "employeeId" && (
                    <Box>
                    <Filter search={employeeIdSearch} setSearch={setEmployeeIdSearch} />
                    </Box>
                    )
                    }
                    {key === "employeeName" && (
                    <Box>
                        <Filter search={employeeNameSearch} setSearch={setEmployeeNameSearch} />
                    </Box>
                    )
                    }
                    {key === "projectCode" && (
                    <Box>
                    <Filter search={projectCodeSearch} setSearch={setProjectCodeSearch} />
                    </Box>
                    )
                    }
                    {key === "category" && (
                    <Box>
                    <Filter search={categorySearch} setSearch={setcategorySearch} />
                    </Box>
                    )
                    }
                    {key === "spendDate" && (
                    <Box>
                    <Filter search={dateSearch} setSearch={setdateSearch} />
                    </Box>
                    )
                    }
                    </Box>
                    </TableCell>
                    ))}
                    </TableRow>
                    </TableHead>
                    <TableBody>
                    {FilterFourData(allSummary, employeeIdSearch, employeeNameSearch, projectCodeSearch, categorySearch, dateSearch).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => (
                    <TableRow key={index}>
                    {keys.map((key) => (
                    <TableCell align="center" key={key}>
                    {key === "images" ? (
                    row[key] ? (
                    <img src={`${expenseDocs}${row[key]}`} style={{ width: 100, height: 100 }} alt={row[key]} />
                    ) : (
                    "-"
                    )
                    ) : key === "audio_file" ? (
                    row[key] ? (
                    <audio controls>
                    <source src={`${expenseDocs}${row[key]}`} type="audio/mpeg" />
                    Your browser does not support the audio element.
                    </audio>
                    ) : (
                    "-"
                    )
                    ) : (
                    row[key]
                    )}
                    </TableCell>
                    ))}
                    </TableRow>
                    ))}
                    </TableBody>
                    </Table>
                    </TableContainer>
                    <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={FilterFourData(allSummary, employeeIdSearch, employeeNameSearch, projectCodeSearch, categorySearch, dateSearch).length}
                    page={page}
                    rowsPerPage={rowsPerPage}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                    </Grid>
                </Box>


            </Container>
        </Box>
    )
}
