const FilterData = (data, search, searchfeild) => {
  const { searchFeildOne, searchFeildTwo } = searchfeild;
  if (search === '') {
    return data
  } else if (searchFeildOne.toString().toLowerCase().includes(search.toLowerCase()) || searchFeildTwo.toString().toLowerCase().includes(search.toLowerCase())) {
    return data
  }
}


// FilterData function now accepts separate filters for employeeId and projectCode
const FilterTwoData = (data, employeeIdSearch, projectCodeSearch) => {
  if (!employeeIdSearch && !projectCodeSearch) {
    return data;
  }

  return data.filter((row) => {
    if (employeeIdSearch && row.employeeId.toString().toLowerCase().includes(employeeIdSearch.toLowerCase())) {
      return true; // Include in the filter if it matches the employeeId search.
    }

    if (projectCodeSearch && row.projectCode.toString().toLowerCase().includes(projectCodeSearch.toLowerCase())) {
      return true; // Include in the filter if it matches the projectCode search.
    }

    return false; // Exclude if it doesn't match either search.
  });
};

const FilterFourData = (data, employeeIdSearch, employeeNameSearch, projectCodeSearch, categorySearch, dateSearch) => {
  if (!employeeIdSearch && !projectCodeSearch && !categorySearch && !dateSearch && !employeeNameSearch) {
    return data;
  }

  return data.filter((row) => {
    if (employeeIdSearch && row.employeeId && row.employeeId.toString().toLowerCase().includes(employeeIdSearch.toLowerCase())) {
      return true; // Include in the filter if it matches the employeeId search.
    }

    if (projectCodeSearch && row.projectCode && row.projectCode.toString().toLowerCase().includes(projectCodeSearch.toLowerCase())) {
      return true; // Include in the filter if it matches the projectCode search.
    }

    if (categorySearch && row.category && row.category.toString().toLowerCase().includes(categorySearch.toLowerCase())) {
      return true; // Include in the filter if it matches the category search.
    }

    if (dateSearch && row.expenseDate && row.expenseDate.toString().toLowerCase().includes(dateSearch.toLowerCase())) {
      return true; // Include in the filter if it matches the date search.
    }

    if (employeeNameSearch && row.employeeName && row.employeeName.toString().toLowerCase().includes(employeeNameSearch.toLowerCase())) {
      return true; // Include in the filter if it matches the date search.
    }

    return false; // Exclude if it doesn't match any of the search criteria.
  });
};



const FilterThreeData = (data, search, searchfeild) => {
  const { searchFeildOne, searchFeildTwo, searchFeildThree } = searchfeild;
  if (search === '') {
    return data;
  } else if (
    (searchFeildOne && searchFeildOne.toString().toLowerCase().includes(search.toLowerCase())) ||
    (searchFeildTwo && searchFeildTwo.toString().toLowerCase().includes(search.toLowerCase())) ||
    (searchFeildThree && searchFeildThree.toString().toLowerCase().includes(search.toLowerCase()))
  ) {
    return data;
  }
};



const FilterSite = (data, search, searchfeild) => {
  const { searchFeildOne, searchFeildTwo } = searchfeild;
  if (search === '') {
    return data
  } else if (searchFeildOne.toLowerCase().includes(search.toLowerCase()) || searchFeildTwo.toLowerCase().includes(search.toLowerCase())) {
    return data
  }
}

  const filterEmpOrders = (data, search, searchfeild) => {
    const { searchFeildOne, searchFeildTwo, searchFeildThree } = searchfeild;
    if (search === '') {
      return data
    } else if (searchFeildOne.toLowerCase().includes(search.toLowerCase()) || searchFeildTwo.toLowerCase().includes(search.toLowerCase()) || searchFeildThree.toLowerCase().includes(search.toLowerCase())) {
      return data
    }
  }


export { FilterData, FilterFourData, filterEmpOrders, FilterSite, FilterThreeData, FilterTwoData };