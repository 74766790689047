import React, { useState, useEffect } from "react";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper , Grid, Box, Container, TablePagination } from "@mui/material";
import { expenseDocs, get_all_food_expense, get_all_rental_expense, get_all_transport_expense, get_all_travel_expense } from "../../API_Service/API_Links";
import axios from "axios";
import { FilterData, FilterTwoData } from "../FilterData/FilterData";
import Filter from "../FilterData/Filter";
import SnackBar from "../SnackBar";

export default function FoodTable() {

    const [open, setOpen] = useState(false);
    const [status, setStatus] = useState(false);
    const [color, setColor] = useState(false);
    const [message, setMessage] = useState("");
    const [data, setData] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
   const [search, setSearch] = useState("");

  const [employeeIdSearch, setEmployeeIdSearch] = useState('');
  const [projectCodeSearch, setProjectCodeSearch] = useState('');


  useEffect(()=>{
     axios({
          method:'GET',
          url: get_all_food_expense,
      }).then(res => {
          if (res.data.error) {
              setMessage(res.data.message)
              setOpen(true)
              setStatus(false)
              setColor(false)
          } else {
              setMessage(res.data.message)
              setOpen(true)
              setStatus(true)
              setColor(true)
              setData(res.data.data);
          }
      }).catch(err => {
          alert('Oops something went wrong ' + err)
      });
  },[]);

  const keys = Object.keys(data[0] || {}).filter((key) => key !== "foodId");

    const handleChangePage = (event, newPage) => {
    setPage(newPage);
};
const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
};

  return (
      <Box boxShadow={4} bgcolor='#EDF4F4'>   
      <SnackBar
        open={open}
        message={message}
        setOpen={setOpen}
        status={status}
        color={color}
      />
   <Container>
    <Box mt={2} py={4}>
    <Grid container spacing={1}>
      <TableContainer sx={{border:'1px solid silver'}} >
         <Table>
         <TableHead sx={{ whiteSpace: 'nowrap', bgcolor: 'success.main' }}>
          <TableRow>
            {keys.map((key) => (
             <TableCell sx={{color: 'white', fontWeight: 500 , textAlign:'center'  }} key={key}>
                <Box sx={{ display: 'flex' , justifyContent:'center'}}>
                <Box sx={{ my: 'auto', textTransform: 'uppercase' }}>
                    {key}
                </Box>
                  {key === "employeeId" && (
                    <Box>
                      <Filter search={employeeIdSearch} setSearch={setEmployeeIdSearch} />
                    </Box>
                  )
                  }
                  {key === "projectCode" && (
                    <Box>
                      <Filter search={projectCodeSearch} setSearch={setProjectCodeSearch} />
                    </Box>
                  )
                  }
            </Box>
             </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
                  {FilterTwoData(data, employeeIdSearch, projectCodeSearch)
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((row, index) => (
              <TableRow key={index}>
                {keys.map((key) => (
                  <TableCell align="center" key={key}>
                    {key === "foodImages" ? (
                      row[key] ? (
                        <img src={`${expenseDocs}${row[key]}`} style={{ width: 100, height: 100 }} alt={row[key]} />
                      ) : (
                        "-"
                      )
                    ) : key === "foodAudio" ? (
                      row[key] ? (
                        <audio controls>
                          <source src={`${expenseDocs}${row[key]}`} type="audio/mpeg" />
                          Your browser does not support the audio element.
                        </audio>
                      ) : (
                        "-"
                      )
                    ) : (
                      row[key]
                    )}
                  </TableCell>
                ))}
              </TableRow>
            ))}
        </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={FilterTwoData(data, employeeIdSearch, projectCodeSearch).length}
            page={page}
            rowsPerPage={rowsPerPage}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
        />
          </Grid>
          </Box>


</Container>
  </Box>
  )
}
