import React from 'react';
import Button from '@mui/material/Button';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { get_summary_Report, methodPost } from '../../API_Service/API_Links';
import axios from 'axios';

export const SummaryAllExport = ({ setIsFilterOn ,SelectedCategory, SelectedProjectCode , fileName, EmployeeId, fromDate, toDate, setMessage , setStatus, setOpen, setColor }) => {
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';

            const runExport = () => {
            const sendData = new FormData()
            sendData.append('EmployeeId', EmployeeId)
            sendData.append('FromDate', fromDate)
            sendData.append('ToDate', toDate)
            sendData.append('ProjectCode', SelectedProjectCode)
            sendData.append('Category', SelectedCategory)
            axios({
            method: methodPost,
            url: get_summary_Report,
            data: sendData
            })
            .then((res) => {
            if (!res.data.error) {
                setMessage(res.data.message);
                setStatus(true);
                setColor(true);
                exportDataToExcel(res.data.data);
                setIsFilterOn(true);
            } else {
                setMessage(res.data.message);
                setStatus(false);
                setColor(false);
               setOpen(true);
                setIsFilterOn(false);
            }
            })
            .catch((err) => {
            alert("Oops something went wrong " + err);
            });
            };

    const exportDataToExcel = (dataToExport) => {

        const modifiedData = dataToExport.map((row) => ({
            "EmployeeId": row.employeeId, 
            "Sender Name": row.senderName, 
            "Receiver Name": row.receiverName, 
            "Projec tName": row.projectName, 
            "Spend Date": row.dates, 
            "Main Category": row.mainCategory, 
            "Sub Category": row.subCategory, 
            "Descriptions": row.descriptions, 
            "Expense Amount": row.expenseAmount, 
            "Credit Amount": row.creditAmount, 
            "Balance Amount": row.balanceAmount, 
        }));
        // Create a workbook
        const wb = XLSX.utils.book_new();

        // Create a single sheet for all the data
        const ws = XLSX.utils.json_to_sheet(modifiedData);

        // Add the sheet to the workbook
        XLSX.utils.book_append_sheet(wb, ws, 'Summary Data');

        // Generate the Excel file
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const excelData = new Blob([excelBuffer], { type: fileType });

        // Save the file using FileSaver.js
        FileSaver.saveAs(excelData, fileName + fileExtension);
    };


    return (
        <Button
            variant="contained"
            onClick={runExport}
            startIcon={<ExitToAppIcon />}
            sx={{ my: 1 }}
        >
            Export
        </Button>
    );
};
