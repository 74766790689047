import React, { useEffect, useState } from 'react'
import { Box, Button, Card, Grid, TextField, Typography, Container, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Autocomplete, Stack } from "@mui/material";
import axios from 'axios';
import { add_material_inward, factorydepartment, get_factory_employees, get_material_name, issuing_material, methodGet, methodPost } from '../../API_Service/API_Links';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { appendData } from '../../Variables/Variables';
import SnackBar from '../SnackBar';
import IconBreadcrumbs from '../Breadcrumbs';
import Heading from '../Heading';
import { useLocation } from 'react-router-dom';

export default function MaterialIssued() {

    const [open, setOpen] = useState(false);
    const [status, setStatus] = useState(false);
    const [color, setColor] = useState(false);
    const [message, setMessage] = useState("");
    const [options, setOptions] = useState([]);
    const [options1, setOptions1] = useState([]);
    const [options2, setOptions2] = useState([]);
    const [selectedMaterials, setSelectedMaterials] = useState(null);
    const [selectedMaterialsQty, setSelectedMaterialsQty] = useState('');
    const [StoredMaterialsData, setStoredMaterialsData] = useState([]);
    const [PostStoredMaterials, setPostStoredMaterialsData] = useState([]);

    const [GENumber, setGENumber] = useState('');
    const [GRNNumber, setGRNNumber] = useState('');
    const [InvoiceNumber, setInvoiceNumber] = useState('');

    const [selectedDept, setselectedDept] = useState(null);
    const [selectedStaff, setselectedStaff] = useState(null);
    const location = useLocation();
    const ProjectId = location.state.id;

    const getAllMaterial = () => {
        axios({
            method: 'GET',
            url: get_material_name,
        }).then(res => {
            if (res.data.error) {
                setMessage(res.data.message)
                setStatus(false)
                setColor(false)
            } else {
                setMessage(res.data.message)
                setStatus(true)
                setColor(true)
                setOptions(res.data.data);
            }
        }).catch(err => {
            alert('Oops something went wrong ' + err)
        });
    }

    useEffect(() => {
        getAllMaterial();
    }, [])
    

    useEffect(() => {
        axios({
            method: methodGet,
            url: factorydepartment,
        })
            .then((res) => {
                if (res.data.error) {
                    setMessage(res.data.message);
                    setOpen(true);
                    setStatus(false);
                    setColor(false);
                } else {
                    setMessage(res.data.message);
                    setOptions1(res.data.data);
                    setOpen(true);
                    setStatus(true);
                    setColor(true);
                }
            })
            .catch((err) => {
                alert("Oops something went wrong " + err);
            });
    }, []);


    useEffect(() => {
        if (selectedDept !== null && selectedDept !=='') {
            const sendData = new FormData();
            sendData.append("DepartmentId", selectedDept.DepartmentId);
            axios({
                method: methodPost,
                url: get_factory_employees,
                data: sendData,
            })
                .then((res) => {
                    if (res.data.error) {
                        setMessage(res.data.message);
                        setOpen(true);
                        setStatus(false);
                        setColor(false);
                    } else {
                        setMessage(res.data.message);
                        setOptions2(res.data?.data);
                        setOpen(true);
                        setStatus(true);
                        setColor(true);
                    }
                })
                .catch((err) => {
                    alert("Oops something went wrong " + err);
                });
        }
        else {
            setOptions([]);
        }
    }, [selectedDept]);


    const storeMaterial = () => {
        const newObject = { "MaterialName": selectedMaterials.MaterialName, "IssuedQty": selectedMaterialsQty };
        setStoredMaterialsData([...StoredMaterialsData, newObject]);
        const newObject1 = { "MaterialId": selectedMaterials.MaterialId, "IssuedQty": selectedMaterialsQty};
        setPostStoredMaterialsData([...PostStoredMaterials, newObject1])
        setSelectedMaterials(null);
        setSelectedMaterialsQty('');
    }


    const handleSubmit = () => {
        const serverData = {
            EmployeeId: selectedStaff.EmployeeId,
        ProjectId: ProjectId,
        materials:JSON.stringify(PostStoredMaterials),
        }
        const sendData = appendData(serverData);
        axios({
            method: methodPost,
            url: issuing_material,
            data: sendData,
        }).then((res) => {
            if (res.data.error) {
                setMessage(res.data.message);
                setOpen(true);
                setStatus(false);
                setColor(false);
            }
            else if (res.data.warning) {
                setMessage(res.data.message);
                setOpen(true);
                setStatus(true)
                setColor(true)
                setselectedDept('')
                setselectedStaff('')
                setStoredMaterialsData([])
                setSelectedMaterialsQty('');
            }
        }).catch((err) => {
            alert("Oops something went wrong " + err);
        })
    }

    return (
        <Box>

            <Box py={2} px={1}>
                <IconBreadcrumbs
                    previous={'Home'}
                    current={'Factory Project'}
                    link1={`/home`}
                    link2={'/home/project'}
                    currentSection={'Issue Material'}

                />
            </Box>

            <Container>
                <Box py={3}>
                    <Heading title={'Issue Material'} />
                </Box>
            </Container>

            <SnackBar
                open={open}
                message={message}
                setOpen={setOpen}
                status={status}
                color={color}
            />
            <Container>
                <Box component={Card} p={4}>
                    <Box p={2}>
                        <Grid container spacing={2} justifyContent='start'>
                            <Grid item xs={12} sm={12} md={4} lg={4}>
                                <Box mb={3}>
                                    <Autocomplete
                                        id="tags-filled"
                                        options={[...options]}
                                        value={selectedMaterials}
                                        onChange={(event, value) => setSelectedMaterials(value)}
                                        getOptionLabel={(option) => option.MaterialName}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                variant="outlined"
                                                color="secondary"
                                                size="small"
                                                label="Select a Material"
                                                fullWidth
                                            />
                                        )}
                                    />
                                </Box>
                            </Grid>

                            <Grid item xs={12} sm={12} md={4} lg={4}>
                                <Box mb={3}>
                                    <TextField
                                        id="InwardQty"
                                        label="Issued Qty" variant="outlined"
                                        size='small'
                                        color='secondary'
                                        fullWidth
                                        value={selectedMaterialsQty}
                                        onChange={(e) => setSelectedMaterialsQty(e.target.value)}
                                    />
                                </Box>
                            </Grid>

                            <Grid item xs={12} sm={12} md={4} lg={4}>
                                <Box mb={3}>
                                    <Button variant='contained' fullWidth onClick={storeMaterial} ><AddCircleOutlineIcon sx={{ verticalAlign: 'middle' }} />Add</Button>
                                </Box>
                            </Grid>

                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                <Typography variant='h6' sx={{ marginBottom: 2 }}>Material & Issued Qty</Typography>
                                <Stack spacing={2}>
                                    {
                                        StoredMaterialsData && StoredMaterialsData.map((i) => {
                                            return (
                                                <Box>
                                                    <Typography variant='body1' fontWeight={600}>{i.MaterialName} - {i.IssuedQty}</Typography>
                                                </Box>
                                            )
                                        })
                                    }
                                </Stack>
                            </Grid>
                        </Grid>

                        <Grid container spacing={2} mt={3}>
                            
                            <Grid item xs={12} sm={6} md={3} lg={3}>
                                <Box mb={3}>
                                    <Box mb={3}>
                                        <TextField
                                            id="ProjectId"
                                            label="Project Id" variant="outlined"
                                            size='small'
                                            color='secondary'
                                            fullWidth
                                            value={ProjectId}
                                        />
                                    </Box>
                                </Box>
                            </Grid>


                            <Grid item xs={12} sm={6} md={3} lg={3}>
                                <Box mb={3}>
                                    <Autocomplete
                                        id="tags-filled"
                                        options={options1}
                                        value={selectedDept}
                                        getOptionLabel={(option) => option.Department}
                                        onChange={(event, value) => setselectedDept(value)}
                                        freeSolo
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                variant="outlined"
                                                color="secondary"
                                                size="small"
                                                label="Department"
                                                placeholder="Favorites"
                                                fullWidth
                                            />
                                        )}
                                    />
                                </Box>
                            </Grid>

                            <Grid item xs={12} sm={6} md={3} lg={3}>
                                <Box mb={3}>
                                    <Autocomplete
                                        id="tags-filled"
                                        options={options2}
                                        getOptionLabel={(option) => `${option.EmployeeName} (${option.EmployeeId})`}
                                        freeSolo
                                        value={selectedStaff}
                                        onChange={(event, value) => setselectedStaff(value)}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                variant="outlined"
                                                color="secondary"
                                                size="small"
                                                label="Staff Selection"
                                                placeholder="Favorites"
                                                fullWidth
                                            />
                                        )}
                                    />
                                </Box>
                            </Grid>

                            <Grid item xs={12} sm={6} md={3} lg={3}>
                                <Box display='flex' justifyContent='center'>
                                    <Button variant='contained' color='secondary' onClick={handleSubmit} fullWidth>Submit</Button>
                                </Box>
                            </Grid>
                        </Grid>

                    </Box>
                </Box>
            </Container>
        </Box>
    )
}
